/* General */

hr {
  border-top: 1px solid rgb(0 0 0 / 28%);
}

.btn {
  background: gray;
  border: black;
}

.btn a {
  color: white;
}

/* BlogMain */

.blog-container {
  display: flex;
  flex-direction: column;
  margin: 0rem 3rem 1rem 3rem;
  text-align: left;
  align-items: center;
}

.blog-intro {
  margin: 3rem 0rem;
  text-align: center;
}

.blog-intro img {
  width: 100%;
}

.blog-posts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* BlogSummary Card */

.blog-summary-card {
  margin: 1rem 0;
  padding: 1rem;
  color: black;
  border-color: gray;
  border-width: 2px;
}

.blog-summary-card-title {
  font-weight: bold;
  font-size: xx-large;
  text-align: center;
  margin: 0.75rem 0rem 1rem 0rem;
}

.blog-summary-card-subtitle {
  font-size: large;
  text-align: center;
}

.blog-summary-card-text {
  font-family: serif;
}

.blog-summary-card-buttons {
  display: flex;
  justify-content: flex-end;
}

/* BlogPost Card */

.blog-post-card {
  color: black;
  padding: 1vh 2vw;
}

.blog-post-card-title {
  font-size: xx-large;
  padding: 1vh 0;
}

.blog-post-card-subtitle {
  font-style: italic;
}

.blog-post-card-text {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
}

.blog-post-card-footer {
  text-align: right;
  margin: 0;
}

.card-img {
  margin: 1em auto 1em auto;
}

/* CreateBlogPost */
.create-blog-post-header {
  margin-top: 2em;
}

.create-blog-post-form {
  width: 70%;
  margin: auto;
}

/* For larger screens */

@media screen and (min-width: 500px) {
  .blog-posts {
    width: 75%;
  }

  .blog-summary-card {
    width: 80%;
  }

  .blog-intro h1 {
    font-size: 4vw;
  }

  .blog-intro p {
    font-size: 2vw;
  }

  .blog-post-card {
    width: 75%;
    margin: auto;
  }

  .card-img {
    width: 75%;
  }
}
