#projects-container {
  margin: 5vw;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-card {
  background-color: #c7c7c7;
  color: black;
}

.project-card-technologies {
  font-style: italic;
  font-size: medium;
  color: #1f1f1f;
  margin-top: 0.75rem;
}

.project-card-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.card-button {
  background-color: #282c34;
  border-color: #282c34;
  font-size: small;
}

.card-button:hover {
  background-color: #575555;
  border-color: #454343;
}

@media screen and (min-width: 1300px) {
  #projects-container {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}
