/* Body and header */

body {
  text-align: center;
  background-color: #282c30;
  color: #d8d8d8;
  font-family: "Cardo", serif;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

#header-photo {
  object-fit: fill;
  width: 100%;
}

#main {
  text-align: center;
}

/* Navigation */

.nav-bar {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.nav-item {
  margin: 0;
  background-color: #282c34;
  border: 0px;
  list-style: none;
  padding: 0px 10px;
}

.nav-item:hover {
  padding: 1vw;
  margin: 0;
  background-color: #282c34;
  border: 0px;
}

/* General Components (Links and Buttons) */

a {
  color: gray;
  font-family: "Roboto Mono", serif;
}

a:hover {
  color: #d8d8d8;
  text-decoration: none;
}

.btn {
  font-family: "Roboto Mono", serif;
  background-color: #282c34;
  border-color: #282c34;
}

.btn-xxl {
  font-size: 1.25rem;
}

.btn:hover {
  background-color: gray;
  border-color: #282c34;
}

.button-area {
  border: none;
}

.card {
  background-color: #d4d4d4;
}

/* For larger screens */

@media screen and (min-width: 460px) {
  .btn-xxl {
    font-size: 1.5rem;
  }

  .nav-item {
    margin: 0 5vw;
  }

  .nav-item:hover {
    margin: 0 5vw;
  }
}
